import { useCreateAdapterMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { adapterFragment } from './useAdapter'

gql`
  mutation CreateAdapter(
    $key: String!
    $url: String
    $username: String
    $password: String
    $retsVersion: String
    $status: AdapterStatus!
    $requestTimeZone: String
    $responseTimeZone: String
    $adapterType: AdapterAdapterType
    $userAgent: String
    $userAgentPassword: String
    $authEndpoint: String
    $scope: String
    $updateDom: Boolean
    $useJwtAuth: Boolean
  ) {
    createAdapter(
      key: $key
      url: $url
      username: $username
      password: $password
      rets_version: $retsVersion
      status: $status
      user_agent: $userAgent
      user_agent_password: $userAgentPassword
      request_time_zone: $requestTimeZone
      response_time_zone: $responseTimeZone
      adapter_type: $adapterType
      auth_endpoint: $authEndpoint
      scope: $scope
      update_dom: $updateDom
      use_jwt_auth: $useJwtAuth
    ) {
      ...adapterFields
    }
  }
  ${adapterFragment}
`

export function useAdapterCreate() {
  const queryClient = useQueryClient()
  return useCreateAdapterMutation({
    onSuccess(data) {
      queryClient.invalidateQueries(['Adapters'])
      queryClient.setQueryData(
        ['Adapter', data.createAdapter?.key],
        data.createAdapter
      )
    }
  })
}
