import { useResumePopulateResourceMutation } from '@forge/graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

gql`
  mutation ResumePopulateResource($statId: ID!) {
    resumePopulateResource(stat_id: $statId) {
      id
    }
  }
`

export function useResumePopulateResource() {
  const queryClient = useQueryClient()
  return useResumePopulateResourceMutation({
    async onSuccess() {
      await queryClient.invalidateQueries(['ResourceGroupStats'])
      await queryClient.invalidateQueries(['Resource'])
    },
    onError(error) {
      console.error('ERROR RESUMING POPULATE RESOURCE', error)
    }
  })
}
