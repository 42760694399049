import {
  Alert,
  Button,
  classNames,
  Pagination,
  StatInfo,
  Table,
  TimeAgo
} from '@forge/common'
import { useAdapterStatDestroy } from '@forge/features/adapters'
import { useResourceGroupStats } from '@forge/features/resourcegroups'
import {
  StatFieldsFragment,
  StatJob,
  StatStatus
} from '@forge/graphql/generated'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { StopIcon } from '@heroicons/react/solid'
import { Suspense, useState } from 'react'
import { useResource } from './useResource'
import { useResumePopulateResource } from './useResumePopulateResource'

interface StatsCondensedProps {
  job: StatJob
  resourceId?: string
  resourceGroupId?: string
  showMls?: boolean
  showJob?: boolean
  showTotal?: boolean
}

function StatsCondensedProvider({
  job,
  showMls,
  showJob,
  showTotal,
  resourceId = '',
  resourceGroupId = ''
}: StatsCondensedProps) {
  const [page, setPage] = useState(1)
  const limit = 12

  const [openedStat, openStat] = useState<StatFieldsFragment | null>(null)
  const [stopJob, setStopJob] = useState<string | null>(null)
  const [resumePopulateStat, setResumePopulateStat] = useState<string | null>()

  const { data: { resource } = {} } = useResource({
    id: resourceId,
    job: job,
    limit,
    page
  })
  const { data: { resourceGroupStats } = {} } = useResourceGroupStats(
    resourceGroupId,
    job,
    limit,
    page
  )
  const { mutate: stopStat, isLoading } = useAdapterStatDestroy()
  const { mutate: resumePopulate, isLoading: isResumingPopulate } =
    useResumePopulateResource()

  const stats = resource?.stats || resourceGroupStats?.stats || []
  const statsInProgress = Boolean(
    stats.filter((stat) => stat.status === 'in_progress').length
  )

  return (
    <>
      <div className="mb-4">
        <Pagination
          disableNext={stats.length < limit}
          disablePrev={page <= 1}
          onPrevClick={() => setPage(page - 1)}
          onNextClick={() => setPage(page + 1)}
          label={`Page ${page}`}
        />
      </div>
      <div className="overflow-auto rounded-lg bg-white py-1 shadow">
        <Table>
          <Table.Head>
            <Table.Row>
              {showMls && <Table.Header align="left">MLS</Table.Header>}
              {showJob && <Table.Header align="left">Job</Table.Header>}
              <Table.Header align="left">Status</Table.Header>
              {showTotal && <Table.Header align="left">Total</Table.Header>}
              <Table.Header align="left">Took</Table.Header>
              <Table.Header align="left">Created</Table.Header>
              <Table.Header align="center">Resume Populate</Table.Header>
              <Table.Header align="right">Stop</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {stats.map((stat: StatFieldsFragment) => {
              const isResumiongPopulateStat =
                resumePopulateStat === stat.id && isResumingPopulate
              return (
                <Table.Row key={stat.id}>
                  {showMls && (
                    <Table.Data onClick={() => openStat(stat)}>
                      {mlsKey({ statable: stat.statable })}
                    </Table.Data>
                  )}
                  {showJob && (
                    <Table.Data onClick={() => openStat(stat)}>
                      {stat.job}
                    </Table.Data>
                  )}
                  <Table.Data onClick={() => openStat(stat)}>
                    <div
                      className={classNames(
                        'absolute top-0 left-0 h-full w-[0.3125rem]',
                        {
                          'bg-gray-400': stat.status === 'unresolved',
                          'bg-blue-500': stat.status === 'ready',
                          'bg-green-500': stat.status === 'success',
                          'bg-yellow-500': ['hold', 'in_progress'].includes(
                            stat.status || ''
                          ),
                          'bg-red-500': [
                            'error',
                            'finished_with_errors',
                            'stopped'
                          ].includes(stat.status || '')
                        }
                      )}
                    />
                    {stat.status}
                  </Table.Data>
                  {showTotal && (
                    <Table.Data onClick={() => openStat(stat)}>
                      {stat.totalRecords}
                    </Table.Data>
                  )}
                  <Table.Data onClick={() => openStat(stat)}>
                    {stat.tookHuman}
                  </Table.Data>
                  <Table.Data onClick={() => openStat(stat)}>
                    {stat.createdAtIso && (
                      <TimeAgo date={new Date(stat.createdAtIso)} />
                    )}
                  </Table.Data>
                  <Table.Data align="center">
                    {(stat.status === StatStatus.FinishedWithErrors ||
                      stat.status === StatStatus.Ready) && (
                      <Button
                        size="xs"
                        disabled={statsInProgress}
                        loading={isResumiongPopulateStat}
                        onClick={() => {
                          setResumePopulateStat(stat.id)
                          resumePopulate(
                            { statId: stat.id },
                            {
                              onSuccess() {
                                setResumePopulateStat(null)
                              }
                            }
                          )
                        }}>
                        {isResumiongPopulateStat ? 'Resuming' : 'Resume'}
                      </Button>
                    )}
                  </Table.Data>
                  <Table.Data align="right">
                    {stat.status === StatStatus.InProgress && (
                      <button
                        className="float-right mr-2 h-5 w-5 text-red-500"
                        onClick={() => setStopJob(stat.id)}>
                        <StopIcon />
                      </button>
                    )}
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
      {!!openedStat && (
        <StatInfo
          isOpen
          size="xl"
          stat={openedStat}
          onClose={() => openStat(null)}
        />
      )}
      <Alert variant="danger" isOpen={!!stopJob}>
        <Alert.Title>Stop Job</Alert.Title>
        <Alert.Content>
          Are you sure you want to stop job <strong>{stopJob}</strong>?
        </Alert.Content>
        <Alert.Cancel onClick={() => setStopJob(null)}>Cancel</Alert.Cancel>
        <Alert.Confirm
          loading={isLoading}
          leftIcon={<ExclamationCircleIcon />}
          onClick={() => {
            if (stopJob) {
              stopStat({ id: stopJob })
              setStopJob(null)
            }
          }}>
          Stop
        </Alert.Confirm>
      </Alert>
    </>
  )
}

const mlsKey = ({ statable }: { statable: StatFieldsFragment['statable'] }) => {
  if (!statable) return null

  switch (statable.__typename) {
    case 'Adapter':
      return statable.key
    case 'ResourceGroup':
      return statable.adapter?.key
    case 'Resource':
      return statable.resourceGroup?.adapter?.key
    default:
      return null
  }
}

export function StatsCondensed(props: StatsCondensedProps) {
  return (
    <Suspense
      fallback={
        <>
          <div className="mb-4">
            <Pagination disablePrev={true} label="Page" />
          </div>
          <div className="overflow-auto rounded-lg bg-white py-1 shadow">
            <Table>
              <Table.Head>
                <Table.Row>
                  {props.showMls && (
                    <Table.Header align="left">MLS</Table.Header>
                  )}
                  {props.showJob && (
                    <Table.Header align="left">Job</Table.Header>
                  )}
                  <Table.Header align="left">Status</Table.Header>
                  {props.showTotal && (
                    <Table.Header align="left">Total</Table.Header>
                  )}
                  <Table.Header align="left">Took</Table.Header>
                  <Table.Header align="left">Created</Table.Header>
                  <Table.Header align="right">Stop</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {Array.from({ length: 15 }).map((_, index) => (
                  <Table.Row key={index}>
                    {props.showMls && (
                      <Table.Data>
                        <div className="mt-1 h-4 w-9 animate-pulse rounded bg-gray-200" />
                      </Table.Data>
                    )}
                    {props.showJob && (
                      <Table.Data>
                        <div className="mt-1 h-4 w-9 animate-pulse rounded bg-gray-200" />
                      </Table.Data>
                    )}
                    <Table.Data>
                      <div className="absolute top-0 left-0 h-full w-[0.3125rem] animate-pulse bg-gray-200" />
                      <div className="mt-1 h-4 w-14 animate-pulse rounded bg-gray-200" />
                    </Table.Data>
                    {props.showTotal && (
                      <Table.Data>
                        <div className="flex justify-center">
                          <div className="mt-1 h-4 w-9 animate-pulse rounded bg-gray-200" />
                        </div>
                      </Table.Data>
                    )}
                    <Table.Data>
                      <div className="flex justify-center">
                        <div className="mt-1 h-4 w-24 animate-pulse rounded bg-gray-200" />
                      </div>
                    </Table.Data>
                    <Table.Data>
                      <div className="flex justify-center">
                        <div className="mt-1 h-4 w-40 animate-pulse rounded bg-gray-200" />
                      </div>
                    </Table.Data>
                    <Table.Data>
                      <div className="flex justify-end">
                        <div className="mt-1 h-4 w-9 animate-pulse rounded bg-gray-200" />
                      </div>
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
      }>
      <StatsCondensedProvider {...props} />
    </Suspense>
  )
}
