import {
  Button,
  Checkbox,
  FormField,
  Input,
  Select,
  toast
} from '@forge/common'
import { AdapterAdapterType, AdapterStatus } from '@forge/graphql/generated'
import { BeakerIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useAdapterCreate } from './useAdapterCreate'

const schema = yup.object({
  adapterType: yup.string().required('Adapter Type is required'),
  key: yup.string().required('Key is required'),
  url: yup.string().required('URL is required'),
  status: yup.string().required('Status is required'),
  requestTimeZone: yup.string().required('Request Timezone is required'),
  responseTimeZone: yup.string().required('Response Timezone is required'),
  updateDom: yup.boolean(),
  username: yup.string().when('adapterType', {
    is: (val: string) => !['bridge', 'flex', 'mlsgrid_v2'].includes(val),
    then: yup.string().required('Username is required')
  }),
  password: yup
    .string()
    .when('adapterType', {
      is: 'bridge',
      then: yup.string().required('API Key is required')
    })
    .required('Password is required'),
  userAgent: yup.string().when('adapterType', {
    is: (val: string) =>
      ['rets', 'rets_reso', 'rapattoni', 'rets_sub_class'].includes(val),
    then: yup.string()
  }),
  userAgentPassword: yup.string().when('adapterType', {
    is: (val: string) =>
      ['rets', 'rets_reso', 'rapattoni', 'rets_sub_class'].includes(val),
    then: yup.string()
  }),
  retsVersion: yup.string().when('adapterType', {
    is: (val: string) => ['rets', 'rets_reso', 'rets_sub_class'].includes(val),
    then: yup.string().required('RETS Version is required')
  }),
  authEndpoint: yup.string().when('adapterType', {
    is: (val: string) =>
      [
        'reso',
        'trestle',
        'rapattoni',
        'reso_transport',
        'paragon',
        'reso_token'
      ].includes(val),
    then: yup.string().required('Auth Endpoint is required')
  }),
  scope: yup.string().when('adapterType', {
    is: (val: string) =>
      ['reso', 'trestle', 'rapattoni', 'reso_transport', 'paragon'].includes(
        val
      ),
    then: yup.string().required('Odata Scope is required')
  }),
  useJwtAuth: yup.boolean()
})

export function AdapterNewForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setValue
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  const {
    mutate: createAdapter,
    isLoading,
    isSuccess,
    error
  } = useAdapterCreate()

  const navigate = useNavigate()

  const currentAdapterType = watch('adapterType')

  useEffect(() => {
    clearErrors()
  }, [clearErrors, currentAdapterType])

  useEffect(() => {
    if (currentAdapterType !== 'reso_token') setValue('useJwtAuth', false)
  }, [currentAdapterType])

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          createAdapter(
            {
              key: data.key,
              url: data.url,
              username: data.username || '',
              password: data.password,
              retsVersion: data.retsVersion,
              status: data.status as AdapterStatus,
              userAgent: data.userAgent,
              userAgentPassword: data.userAgentPassword,
              requestTimeZone: data.requestTimeZone,
              responseTimeZone: data.responseTimeZone,
              adapterType: data.adapterType as AdapterAdapterType,
              authEndpoint: data.authEndpoint,
              scope: data.scope,
              updateDom: data.updateDom,
              useJwtAuth: data.useJwtAuth
            },
            {
              onSuccess({ createAdapter }) {
                toast(
                  <div className="space-y-0.5">
                    <strong>Adapter added successfully</strong>
                  </div>,
                  { variant: 'success' }
                )
                navigate(`/adapters/${createAdapter?.id}/details`)
              }
            }
          )
        })}
        className="space-y-3">
        <div className="w-full">
          <FormField
            label="Adapter Type"
            error={errors.adapterType?.message}
            required>
            <Select {...register('adapterType')}>
              <option value="">Select an Adapter Type</option>
              <option value="bridge">Bridge Interactive</option>
              <option value="flex">Flex RESO Web API</option>
              <option value="mlsgrid_v2">MLSGRID</option>
              <option value="paragon">Paragon Web API</option>
              <option value="rapattoni">Rapattoni</option>
              <option value="reso_token">RESO Token</option>
              <option value="reso_transport">RESO Transport</option>
              <option value="reso">RESO</option>
              <option value="rets">RETS</option>
              <option value="rets_sub_class">RetsQuery Sub Class</option>
              <option value="rets_reso">RETS RESO</option>
              <option value="trestle">Trestle</option>
            </Select>
          </FormField>
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField
              label="Adapter Status"
              error={errors.status?.message}
              required>
              <Select {...register('status')}>
                <option value="">Select a Status</option>
                <option value="not_started">Not Started</option>
                <option value="in_progress">In Progress</option>
                <option value="live">Live</option>
                <option value="depricated">Depricated</option>
              </Select>
            </FormField>
          </div>
          <div className="w-full pb-2">
            <FormField label="Adapter Key" error={errors.key?.message} required>
              <Input {...register('key')} placeholder="Enter Adapter Key" />
            </FormField>
          </div>
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-full pb-2">
            <FormField
              label="Request Time Zone"
              error={errors.requestTimeZone?.message}
              required>
              <Select {...register('requestTimeZone')}>
                <option value="">Select a Time Zone</option>
                <option value="UTC">UTC</option>
                <option value="Atlantic Time (Canada)">
                  Atlantic Time (Canada)
                </option>
                <option value="Eastern Time (US & Canada)">
                  Eastern Time (US & Canada)
                </option>
                <option value="Central Time (US & Canada)">
                  Central Time (US & Canada)
                </option>
                <option value="Mountain Time (US & Canada)">
                  Mountain Time (US & Canada)
                </option>
                <option value="Arizona">Arizona</option>
                <option value="Pacific Time (US & Canada)">
                  Pacific Time (US & Canada)
                </option>
                <option value="Hawaii">Hawaii</option>
                <option value="Alaska">Alaska</option>
              </Select>
            </FormField>
          </div>
          <div className="w-full pb-2">
            <FormField
              label="Response Time Zone"
              error={errors.responseTimeZone?.message}
              required>
              <Select {...register('responseTimeZone')}>
                <option value="">Select a Time Zone</option>
                <option value="UTC">UTC</option>
                <option value="Atlantic Time (Canada)">
                  Atlantic Time (Canada)
                </option>
                <option value="Eastern Time (US & Canada)">
                  Eastern Time (US & Canada)
                </option>
                <option value="Central Time (US & Canada)">
                  Central Time (US & Canada)
                </option>
                <option value="Mountain Time (US & Canada)">
                  Mountain Time (US & Canada)
                </option>
                <option value="Arizona">Arizona</option>
                <option value="Pacific Time (US & Canada)">
                  Pacific Time (US & Canada)
                </option>
                <option value="Hawaii">Hawaii</option>
                <option value="Alaska">Alaska</option>
              </Select>
            </FormField>
          </div>
        </div>
        {watch('adapterType') === 'rets' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField label="User Agent" error={errors.userAgent?.message}>
                  <Input
                    {...register('userAgent')}
                    placeholder="Enter User Agent"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  label="User Agent Password"
                  error={errors.userAgentPassword?.message}>
                  <Input
                    {...register('userAgentPassword')}
                    placeholder="Enter User Agent Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="w-full">
              <FormField
                required
                label="RETS Version"
                error={errors.retsVersion?.message}>
                <Select {...register('retsVersion')}>
                  <option value="">Select a RETS Version</option>
                  <option value="1.5">RETS Version 1.5</option>
                  <option value="1.7.2">RETS Version 1.7.2</option>
                  <option value="1.8">RETS Version 1.8</option>
                </Select>
              </FormField>
            </div>
          </>
        )}
        {watch('adapterType') === 'rets_reso' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField label="User Agent" error={errors.userAgent?.message}>
                  <Input
                    {...register('userAgent')}
                    placeholder="Enter User Agent"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  label="User Agent Password"
                  error={errors.userAgentPassword?.message}>
                  <Input
                    {...register('userAgentPassword')}
                    placeholder="Enter User Agent Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="w-full">
              <FormField
                required
                label="RETS Version"
                error={errors.retsVersion?.message}>
                <Select {...register('retsVersion')}>
                  <option value="">Select a RETS Version</option>
                  <option value="1.5">RETS Version 1.5</option>
                  <option value="1.7.2">RETS Version 1.7.2</option>
                  <option value="1.8">RETS Version 1.8</option>
                </Select>
              </FormField>
            </div>
          </>
        )}
        {watch('adapterType') === 'rets_sub_class' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField label="User Agent" error={errors.userAgent?.message}>
                  <Input
                    {...register('userAgent')}
                    placeholder="Enter User Agent"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  label="User Agent Password"
                  error={errors.userAgentPassword?.message}>
                  <Input
                    {...register('userAgentPassword')}
                    placeholder="Enter User Agent Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="w-full">
              <FormField
                required
                label="RETS Version"
                error={errors.retsVersion?.message}>
                <Select {...register('retsVersion')}>
                  <option value="">Select a RETS Version</option>
                  <option value="1.5">RETS Version 1.5</option>
                  <option value="1.7.2">RETS Version 1.7.2</option>
                  <option value="1.8">RETS Version 1.8</option>
                </Select>
              </FormField>
            </div>
          </>
        )}
        {watch('adapterType') === 'reso' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Auth Endpoint"
                  error={errors.authEndpoint?.message}>
                  <Input
                    {...register('authEndpoint')}
                    placeholder="Enter Endpoint"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label="Odata Scope"
                  error={errors.scope?.message}>
                  <Input {...register('scope')} placeholder="Enter Scope" />
                </FormField>
              </div>
            </div>
          </>
        )}
        {watch('adapterType') === 'trestle' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Auth Endpoint"
                  error={errors.authEndpoint?.message}>
                  <Input
                    {...register('authEndpoint')}
                    placeholder="Enter Endpoint"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label="Odata Scope"
                  error={errors.scope?.message}>
                  <Input {...register('scope')} placeholder="Enter Scope" />
                </FormField>
              </div>
            </div>
          </>
        )}
        {watch('adapterType') === 'paragon' && (
          <>
            <FormField required label="Adapter URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Auth Endpoint"
                  error={errors.authEndpoint?.message}>
                  <Input
                    {...register('authEndpoint')}
                    placeholder="Enter Endpoint"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label="Odata Scope"
                  error={errors.scope?.message}>
                  <Input {...register('scope')} placeholder="Enter Scope" />
                </FormField>
              </div>
            </div>
          </>
        )}
        {watch('adapterType') === 'reso_transport' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Auth Endpoint"
                  error={errors.authEndpoint?.message}>
                  <Input
                    {...register('authEndpoint')}
                    placeholder="Enter Endpoint"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label="Odata Scope"
                  error={errors.scope?.message}>
                  <Input {...register('scope')} placeholder="Enter Scope" />
                </FormField>
              </div>
            </div>
          </>
        )}
        {watch('adapterType') === 'reso_token' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label={watch('useJwtAuth') ? 'Client ID' : 'Username'}
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label={watch('useJwtAuth') ? 'Client Secret' : 'Password'}
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  label="Auth Endpoint"
                  required
                  error={errors.authEndpoint?.message}>
                  <Input
                    {...register('authEndpoint')}
                    placeholder="Enter Endpoint"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField label="Odata Scope">
                  <Input {...register('scope')} placeholder="Enter Scope" />
                </FormField>
              </div>
            </div>
          </>
        )}
        {watch('adapterType') === 'rapattoni' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Username"
                  error={errors.username?.message}>
                  <Input
                    {...register('username')}
                    placeholder="Enter Username"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label=" Password"
                  error={errors.password?.message}>
                  <Input
                    {...register('password')}
                    placeholder="Enter Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField label="User Agent" error={errors.userAgent?.message}>
                  <Input
                    {...register('userAgent')}
                    placeholder="Enter User Agent"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  label="User Agent Password"
                  error={errors.userAgentPassword?.message}>
                  <Input
                    {...register('userAgentPassword')}
                    placeholder="Enter User Agent Password"
                  />
                </FormField>
              </div>
            </div>
            <div className="flex justify-between space-x-3">
              <div className="w-full pb-2">
                <FormField
                  required
                  label="Auth Endpoint"
                  error={errors.authEndpoint?.message}>
                  <Input
                    {...register('authEndpoint')}
                    placeholder="Enter Endpoint"
                  />
                </FormField>
              </div>
              <div className="w-full">
                <FormField
                  required
                  label="Odata Scope"
                  error={errors.scope?.message}>
                  <Input {...register('scope')} placeholder="Enter Scope" />
                </FormField>
              </div>
            </div>
          </>
        )}
        {watch('adapterType') === 'flex' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>
            <FormField required label="Token" error={errors.password?.message}>
              <Input {...register('password')} placeholder="Enter Auth Token" />
            </FormField>
          </>
        )}
        {watch('adapterType') === 'bridge' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>

            <FormField
              required
              label="API Key"
              error={errors.password?.message}>
              <Input {...register('password')} placeholder="Enter API Key" />
            </FormField>
          </>
        )}
        {watch('adapterType') === 'mlsgrid_v2' && (
          <>
            <FormField required label="URL" error={errors.url?.message}>
              <Input {...register('url')} placeholder="Enter URL" />
            </FormField>

            <FormField
              required
              label="API Key"
              error={errors.password?.message}>
              <Input {...register('password')} placeholder="Enter API Key" />
            </FormField>
          </>
        )}
        <div>
          <div className="mt-5 mb-2 ml-2">
            {watch('adapterType') === 'reso_token' && (
              <FormField>
                <Checkbox
                  variant="primary"
                  defaultChecked={false}
                  {...register('useJwtAuth')}>
                  Use JWT Authentication
                </Checkbox>
              </FormField>
            )}
            <FormField>
              <Checkbox
                variant="primary"
                defaultChecked={false}
                {...register('updateDom')}>
                Automatic DOM Updates <br></br>(check this box to auto increment
                DOM for active listings)
              </Checkbox>
            </FormField>
          </div>
          <Button
            fullWidth
            variant={!error ? 'primary' : 'danger'}
            type="submit"
            loading={isLoading}
            leftIcon={
              error ? (
                <ExclamationCircleIcon />
              ) : isSuccess ? (
                <BeakerIcon />
              ) : undefined
            }>
            {!!error && <>An Error Occured. Try again?</>}
            {!error && (
              <>
                {isLoading
                  ? 'Adding Adapter'
                  : isSuccess
                  ? 'Success'
                  : `Add Adapter`}
              </>
            )}
          </Button>
        </div>
        {error && (
          <p role="alert" aria-live="polite" className="text-sm text-red-600">
            {error.message}
          </p>
        )}
      </form>
    </div>
  )
}
