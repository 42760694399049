import { Tabs } from '@forge/common'
import { useParams, useSearchParams } from 'react-router-dom'
import { ResourceGroupFactsConsumerTable } from './ResourceGroupFactsConsumerTable'
import { ResourceGroupPercolatorStatsTable } from './ResourceGroupPercolatorStatsTable'
import { ResourceGroupRecordsConsumerTable } from './ResourceGroupRecordsConsumerTable'
import { ResourceGroupReindexConsumerTable } from './ResourceGroupReindexConsumerTable'
import { ResourceGroupReplicationApiTable } from './ResourceGroupReplicationApiTable'
import { ResourceGroupResourcesTable } from './ResourceGroupResourcesTable'
import { ResourceGroupSavedSearchConsumerTable } from './ResourceGroupSavedSearchConsumerTable'
import { useResourceGroup } from './useResourceGroup'

enum TabEnum {
  resources,
  recordsConsumer,
  reindexConsumer,
  savedSearchConsumer,
  factsConsumer,
  percolator,
  replicationApi
}

export function ResourceGroupTabs() {
  const { resourceGroupId } = useParams()
  const { data: { resourceGroup } = {} } = useResourceGroup({
    id: resourceGroupId || ''
  })

  const [searchParams, setSearchParams] = useSearchParams({ tab: 'resources' })
  const selectedTab = searchParams.get('tab') as keyof typeof TabEnum
  const selectedTabIndex = TabEnum[selectedTab]

  return (
    <div>
      <Tabs selectedIndex={selectedTabIndex}>
        <Tabs.List>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'resources' })}>
            Resources
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'recordsConsumer' })}>
            Records Consumer
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'reindexConsumer' })}>
            Reindex Consumer
          </Tabs.Tab>
          {resourceGroup?.role === 'listing' && (
            <>
              <Tabs.Tab
                onClick={() => setSearchParams({ tab: 'savedSearchConsumer' })}>
                SavedSearch Consumer
              </Tabs.Tab>
              <Tabs.Tab
                onClick={() => setSearchParams({ tab: 'factsConsumer' })}>
                Facts Consumer
              </Tabs.Tab>
              <Tabs.Tab onClick={() => setSearchParams({ tab: 'percolator' })}>
                Percolator
              </Tabs.Tab>
            </>
          )}
          <Tabs.Tab onClick={() => setSearchParams({ tab: 'replicationApi' })}>
            Replication API
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <ResourceGroupResourcesTable />
          </Tabs.Panel>
          <Tabs.Panel>
            <ResourceGroupRecordsConsumerTable />
          </Tabs.Panel>
          <Tabs.Panel>
            <ResourceGroupReindexConsumerTable />
          </Tabs.Panel>
          {resourceGroup?.role === 'listing' && (
            <>
              <Tabs.Panel>
                <ResourceGroupSavedSearchConsumerTable />
              </Tabs.Panel>
              <Tabs.Panel>
                <ResourceGroupFactsConsumerTable />
              </Tabs.Panel>
              <Tabs.Panel>
                <ResourceGroupPercolatorStatsTable />
              </Tabs.Panel>
            </>
          )}
          <Tabs.Panel>
            <ResourceGroupReplicationApiTable resourceGroup={resourceGroup} />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </div>
  )
}
